<template>
  <modal
    :exibir="exibir"
    :titulo="$t('AUTENTIFICACAO_MODAL_ESQUECEU_SENHA.ESQUECEU_SENHA')"
    tamanho="md"
    :campos="$refs"
    :ocultarRodape="true"
    @hidden="fecharModal()"
  >
    <p>{{ $t('AUTENTIFICACAO_MODAL_ESQUECEU_SENHA.EXPLICACAO') }}</p>
    <input-text
      ref="email"
      v-model="form.email"
      type="email"
      :placeholder="$t('AUTENTIFICACAO_MODAL_ESQUECEU_SENHA.EMAIL')"
      required
    />
    <b-button
      variant="primary"
      size="md"
      block
      class="mt-4"
      @click="enviarEmail"
    >
      {{ $t('AUTENTIFICACAO_MODAL_ESQUECEU_SENHA.ALTERAR_SENHA') }}
    </b-button>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import UsuarioService from '@/common/services/usuario/usuario.service';

// Components:
import Modal from '@/components/modal/Modal.vue';
import InputText from '@/components/inputs/InputText';

export default {
  name: 'ModalEsqueceuSenha',
  props: {
    exibir: { type: Boolean, default: false },
  },
  components: {
    Modal,
    InputText,
  },
  data() {
    return {
      form: {
        email: null,
      },
    };
  },
  methods: {
    validarFormulario() {
      let arValidation = [];
      arValidation.push(this.$refs.email.valid());

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    enviarEmail() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      const form = this.form;
      UsuarioService.esqueceuSenha(form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('GERAL.EMAIL_ENVIADO')
          );
          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    fecharModal() {
      this.$emit('fecharModal');
    },
  },
};
</script>
