<template>
  <div id="login">
    <b-card no-body class="w-100">
      <b-row class="no-gutters" style="min-height: 100vh">
        <b-col
          lg="6"
          class="bg-cej d-md-flex align-items-center justify-content-center"
        />
        <b-col
          lg="6"
          class="d-md-flex align-items-center justify-content-center"
        >
          <b-row class="justify-content-center w-100 p-5 m-0">
            <b-col cols="12" lg="8" xl="6">
              <div class="d-flex mb-4">
                <img
                  class="m-auto login__logo-img"
                  src="@/assets/images/logo.png"
                />
              </div>

              <h2 class="font-weight-bold">
                {{ $t('AUTENTIFICACAO_LOGIN.LOGIN') }}
              </h2>

              <!-- inputs: email && password -->
              <input-text
                ref="email"
                v-model="form.email"
                noLabel
                :label="$t('AUTENTIFICACAO_LOGIN.EMAIL')"
                :placeholder="$t('AUTENTIFICACAO_LOGIN.EMAIL')"
                @enter="login"
                required
              />
              <input-text
                ref="senha"
                v-model="form.senha"
                noLabel
                type="password"
                :label="$t('AUTENTIFICACAO_LOGIN.SENHA')"
                :placeholder="$t('AUTENTIFICACAO_LOGIN.SENHA')"
                @enter="login"
                required
              />

              <!-- btn action -->
              <b-button
                variant="primary"
                size="lg"
                block
                class="mt-4"
                @click="login"
              >
                {{ $t('GERAL.LOGIN') }}
              </b-button>

              <!-- forgot password link -->
              <div class="ml-auto mt-2">
                <a
                  @click="openModal('esqueceuSenha')"
                  href="javascript:void(0)"
                  class="link"
                >
                  {{ $t('AUTENTIFICACAO_LOGIN.ESQUECEU_SENHA') }}
                </a>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <modal-esqueceu-senha
      :exibir="modais.esqueceuSenha"
      @fecharModal="closeModal('esqueceuSenha')"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import { LOGIN } from '@/store/autenticacao.module';
import mensagem from '@/common/utils/mensagem';
import modais from '@/common/utils/modais';
import rotas from '@/common/utils/rotas';
import helpers from '@/common/utils/helpers';

// Components:
import InputText from '@/components/inputs/InputText';
import ModalEsqueceuSenha from './components/ModalEsqueceuSenha';

export default {
  name: 'Login',
  components: {
    InputText,
    ModalEsqueceuSenha,
  },
  data() {
    return {
      //TODO: REMOVER ISSO DEPOIS
      form: {
        email: process.env.VUE_APP_EMAIL || '',
        senha: process.env.VUE_APP_PASS || '',
      },
      modais: {
        esqueceuSenha: false,
      },
    };
  },
  methods: {
    validarFormulario() {
      let arValidation = [];
      arValidation.push(this.$refs.email.valid());
      arValidation.push(this.$refs.senha.valid());

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    login() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      const form = this.form;

      this.$store
        .dispatch(LOGIN, {
          ...form,
          senha: helpers.Encriptar(form.senha),
        })
        .then(() => rotas.irPara('dashboard'))
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
  },
};
</script>
<style>
#login {
  min-height: calc(100vh - 20px);
}
.login__logo-img {
  width: 250px;
  max-width: 100%;
}
</style>
